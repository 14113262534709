import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
import Note from "../../components/text-decorations/note";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "vim-modes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#vim-modes",
        "aria-label": "vim modes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Vim Modes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`esc`}</inlineCode>{` - Normal Modes`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`i`}</inlineCode>{` - Insert Mode`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`v`}</inlineCode>{` - Visual Mode`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`R`}</inlineCode>{` - Replace Mode`}</li>
    </ul>
    <h2 {...{
      "id": "colon-commands",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#colon-commands",
        "aria-label": "colon commands permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Colon commands`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`:w`}</inlineCode>{` - write/save file`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`w <filename>`}</inlineCode>{` - white to provided filename`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`:q`}</inlineCode>{` - quit vim`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`:q!`}</inlineCode>{` - force quit vim (changes will be discarded)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`:set number`}</inlineCode>{` - shows line numbers`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`:set nonumber`}</inlineCode>{` - hides line numbers`}</li>
    </ul>
    <h2 {...{
      "id": "moving-around",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#moving-around",
        "aria-label": "moving around permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Moving Around`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`h`}</inlineCode>{` or `}<inlineCode parentName="li">{`arrow left`}</inlineCode>{` - move the cursor one character to the left`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`l`}</inlineCode>{` or `}<inlineCode parentName="li">{`arrow right`}</inlineCode>{` - move the cursor one character to the right`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`j`}</inlineCode>{` or `}<inlineCode parentName="li">{`arrow down`}</inlineCode>{` - move the cursor one line down`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`k`}</inlineCode>{` or `}<inlineCode parentName="li">{`arrow up`}</inlineCode>{` - move the cursor one line up`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`0`}</inlineCode>{` - move the cursor to the beginning of the line`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`$`}</inlineCode>{` - move the cursor to the end of the line`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`w`}</inlineCode>{` - move the cursor one word forward`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`b`}</inlineCode>{` - move the cursor one word back`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`gg`}</inlineCode>{` - move to the beginning of the file (line 1)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`G`}</inlineCode>{` - move to the end of the line (last line)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`o`}</inlineCode>{` - adds an empty line below the cursor, moves the cursor to that line, enters edit mode`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`O`}</inlineCode>{` - adds an empty line above the cursor, moves the cursor to that line, enters edit mode`}</li>
    </ul>
    <h2 {...{
      "id": "deleting-things",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#deleting-things",
        "aria-label": "deleting things permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deleting things`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`de`}</inlineCode>{` - delete from where the cursor is until the end of the current word`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`dw`}</inlineCode>{` - delete to next word`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`d2w`}</inlineCode>{` - delete two words from cursor`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`d$`}</inlineCode>{` or `}<inlineCode parentName="li">{`D`}</inlineCode>{` - delete to the end of the line from where the cursor is`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`dd`}</inlineCode>{` - delete the whole line (cut)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`x`}</inlineCode>{` - delete character`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`s`}</inlineCode>{` - delete character and insert`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`S`}</inlineCode>{` - delete line and insert`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`C`}</inlineCode>{` - delete until the end of line and insert`}</li>
    </ul>
    <h2 {...{
      "id": "editing-things",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#editing-things",
        "aria-label": "editing things permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Editing things`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`a`}</inlineCode>{` - append enter insert mode one char right of cursor`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`A`}</inlineCode>{` - append from end of line`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`r`}</inlineCode>{` - replace one character on cursor`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`R`}</inlineCode>{` - enter replace mode`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`u`}</inlineCode>{` - undo changes`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`gU<direction>`}</inlineCode>{` - uppercase character in chosen direction`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`gu<direction`}</inlineCode>{` - lowercase character in chosen direction`}</li>
    </ul>
    <h2 {...{
      "id": "copy-and-pasting",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#copy-and-pasting",
        "aria-label": "copy and pasting permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Copy and pasting`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`dd`}</inlineCode>{` - cut the whole line`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`d<direction>`}</inlineCode>{` - cut character in chosen direction`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`yy`}</inlineCode>{` - copy the whole line`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`y<direction>`}</inlineCode>{` - copy the character in chosen direction`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`p`}</inlineCode>{` - paste content`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`P`}</inlineCode>{` - paste before cursor`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`"*p`}</inlineCode>{` - paste from system clipboard`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`"*y`}</inlineCode>{` - copy to system clipboard`}</li>
    </ul>
    <h2 {...{
      "id": "search-for-things",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#search-for-things",
        "aria-label": "search for things permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Search for things`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`:?<term>`}</inlineCode>{` - searches for that term from the cursor down`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`:/<term>`}</inlineCode>{` - searches for that term from the cursor up`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`*`}</inlineCode>{` - search whole word from the cursor down`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`#`}</inlineCode>{` - search whole word from the cursor up`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`n`}</inlineCode>{` - go to the next searched term`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`N`}</inlineCode>{` - go to the previous searched term`}</li>
    </ul>
    <h2 {...{
      "id": "replacing-things",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#replacing-things",
        "aria-label": "replacing things permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Replacing things`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`:s/<search term>/<replace term>`}</inlineCode>{` - search for and replace term in the current line and replace`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`:%s/<search term>/<replace term>`}</inlineCode>{` - search for and replace term in the whole file`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`:{from, to}s/<search term>/<replace term> `}</inlineCode>{` - search for and replace term from line `}<inlineCode parentName="li">{`from`}</inlineCode>{` to line `}<inlineCode parentName="li">{`to`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "flags-that-you-can-use",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#flags-that-you-can-use",
        "aria-label": "flags that you can use permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Flags that you can use`}</h3>
    <Note mdxType="Note">
Flags can be used at the end of the search command for example <code>:s/bob/bobthebuilder/g</code> you can also combine flag together.
    </Note>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`g`}</inlineCode>{` - search and replace globaly in the file`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`i`}</inlineCode>{` - ignore case when searching`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`c`}</inlineCode>{` - ask confirmation before replacing `}</li>
    </ul>
    <h3 {...{
      "id": "useful-searchreplace-commands",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#useful-searchreplace-commands",
        "aria-label": "useful searchreplace commands permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Useful search/replace commands`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`:2,10s/^/#/`}</inlineCode>{` - comments lines from 2 to 10`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`:2,10/^#/`}</inlineCode>{` - uncomment lines from 2 to 10`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`:%s/\\s\\+$//e`}</inlineCode>{` - remove trailing white space at the end of each line`}</li>
    </ul>
    <h2 {...{
      "id": "tabs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#tabs",
        "aria-label": "tabs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tabs`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`vim -p <filename> <filename>`}</inlineCode>{` open file in a separate tab`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`:tabnew`}</inlineCode>{` - opens a new tab page with an empty window`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`:tabc`}</inlineCode>{` - close current tab`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`:tabc!`}</inlineCode>{` - force close current tab discarding changes`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`tabn`}</inlineCode>{` - move to next tab`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`tabN`}</inlineCode>{` - move to previous tab`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      